import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { APP_INITIALIZER, importProvidersFrom } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { RouteReuseStrategy, provideRouter, withComponentInputBinding } from '@angular/router';
import { AuthInterceptor } from '@interceptors/auth.interceptor';
import { ErrorReqInterceptor } from '@interceptors/error-req.interceptor';
import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone';
import applicationInfoService from '@services/application-info.service';
import { AuthService } from '@services/auth/auth.service';
import BackButtonService from '@services/back-button.service';
import { UserInfoService } from '@services/user-info/user-info.service';
import { NgOtpInputModule } from 'ng-otp-input';
import { NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { SENTRY_PROVIDERS } from 'src/sentry';
import { initAppPlugins, initializeUser } from './initializers';
import appRoutes from './routes';

export default [
  importProvidersFrom(BrowserModule, NgOtpInputModule, MatDialogModule, MatSnackBarModule),
  { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  provideRouter(appRoutes, withComponentInputBinding()),
  NgxMaskPipe,
  provideNgxMask(),
  provideIonicAngular({
    backButtonText: '',
    backButtonDefaultHref: '/',
  }),
  { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
  provideHttpClient(withInterceptors([AuthInterceptor, ErrorReqInterceptor])),
  provideAnimations(),
  {
    provide: APP_INITIALIZER,
    useFactory: initializeUser,
    multi: true,
    deps: [AuthService, UserInfoService],
  },
  {
    provide: APP_INITIALIZER,
    useFactory: initAppPlugins,
    multi: true,
    deps: [BackButtonService, applicationInfoService],
  },
  ...SENTRY_PROVIDERS,
];
