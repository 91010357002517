import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Keyboard } from '@capacitor/keyboard';
import { SplashScreen } from '@capacitor/splash-screen';
import { MainActionSheetMenuComponent } from '@features/main-action-sheet-menu/main-action-sheet-menu.component';
import { IonApp, IonRouterOutlet } from '@ionic/angular/standalone';
import applicationInfoService from '@services/application-info.service';
import BackButtonService from '@services/back-button.service';
import MainActionSheetMenuService from '@services/main-action-sheet-menu/main-action-sheet-menu.service';
import OneSignal from 'onesignal-cordova-plugin';
import { filter } from 'rxjs';

const DEEPLINK_DOMAIN = 'drvn.cc';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [IonApp, IonRouterOutlet, MainActionSheetMenuComponent],
})
export default class AppComponent implements OnInit, OnDestroy {
  showTabs$ = this.mainActionSheetMenuService.show$;
  showSplashScreen = false;
  isResumed = false;

  constructor(
    private readonly applicationInfoService: applicationInfoService,
    private readonly backButtonService: BackButtonService,
    private readonly router: Router,
    private readonly ngZone: NgZone,
    private readonly mainActionSheetMenuService: MainActionSheetMenuService
  ) {}

  ngOnInit() {
    this.listenForShowTabsChanges();
    if (this.applicationInfoService.isApp) {
      this.initSplashScreen();
      this.handleDeeplinking();
      this.initOneSignal();
    }
  }

  initSplashScreen() {
    this.showSplashScreen = true;
    setTimeout(() => {
      SplashScreen.hide();
    }, 200);
    setTimeout(() => {
      this.showSplashScreen = false;
    }, 2250);
  }

  handleDeeplinking() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.ngZone.run(() => {
        this.redirectToUrl(event.url);
      });
    });
  }

  initOneSignal() {
    OneSignal.Notifications.addEventListener('click', async e => {
      let clickData = await e.notification;
      const launchUrl = clickData.launchURL || DEEPLINK_DOMAIN;
      this.ngZone.run(() => {
        this.redirectToUrl(launchUrl.replace(`${DEEPLINK_DOMAIN}://`, ''));
      });
    });

    OneSignal.Notifications.requestPermission(true).then((success: Boolean) => {});
  }

  redirectToUrl(url: string) {
    //? The path array is now like ['https://drvn.cc', '/reviews']
    const pathArray = url.split(DEEPLINK_DOMAIN);

    // Get the last element with pop
    const appPath = pathArray.pop();

    if (appPath) {
      this.router.navigateByUrl(appPath);
    }
  }

  listenForShowTabsChanges() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
      let currentRoute = this.router.routerState.root;
      while (currentRoute.firstChild) {
        currentRoute = currentRoute.firstChild;
      }

      this.mainActionSheetMenuService.setShowsTabsValue(currentRoute.snapshot?.data['showTabs'] || false);

      if (this.mainActionSheetMenuService.show$()) {
        this.listenKeyboardEvents();
      }
    });
  }

  closeServices() {
    this.backButtonService.removeListener();
  }

  listenKeyboardEvents() {
    Keyboard.addListener('keyboardWillShow', () => {
      this.mainActionSheetMenuService.setShowsTabsValue(false);
    });
    Keyboard.addListener('keyboardWillHide', () => {
      this.mainActionSheetMenuService.setShowsTabsValue(true);
    });
  }

  ngOnDestroy() {
    Keyboard.removeAllListeners();
    this.closeServices();
  }
}
